@import url(https://fonts.googleapis.com/css?family=Montserrat);
.right {
  float: right !important;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

.el-menu-item {
  display: flex !important;
  padding: 0 !important;
  height: 6.4vh !important;
  font-size: calc(0.7em + 0.4vw) !important;
}

.el-menu-item > a, .el-menu-item > div {
  text-decoration: none;
  display: flex;
}

.link {
  align-self: center;
  font-style: 'normal';
}

.nav-links-spacing {
  padding: 0 1.2rem;
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-weight: lighter;
  text-align: center;
  color: #48576a;
}

h1 {
  margin: 0;
  height: 90px;
}

hr {
  border: 1px solid rgb(234, 238, 251);
  border-radius: 5px;
  margin: 30px 0;
  margin-bottom: 85px;
}

.full-width {
  width: 100% !important;
}

.shared-width {
  width: auto !important;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  margin: 5px !important;
}

.double-ctn {
  width: 100%;
  display: inline-block;
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
}

span {
  vertical-align: middle;
}

.main-node {
  fill: #ffffff;
  stroke-width: 2px;
  rx: 6px;
  ry: 6px;
}

.danger-btn {
  color: #eb4d2f !important;
}

.grecaptcha-badge { visibility: hidden; }

blockquote {
  background-color: rgba(0, 0, 0, 0.08);
  width: auto;
  padding: 0 0.5rem;
}

.danger-btn:hover {
  background-color: rgba(235, 77, 47, 0.04) !important;
}

.plussign {
  fill: transparent;
}

.plussign-dragging {
  fill: transparent;
}

.allnodes:hover .plussign {
  fill: #fff;
  stroke-width: 1px;
  stroke: #888;
  rx: 2px;
  ry: 2px;
}

circle.inactive {
  fill: transparent;
}

circle.active {
  fill: #aef;
  stroke-width: 2px;
  stroke: #8cd;
  opacity: 0.2;
}

@-webkit-keyframes color_change {
  from {
    fill: #8cd;
  }
  to {
    fill: white;
  }
}
@keyframes color_change {
  from {
    fill: #8cd;
  }
  to {
    fill: white;
  }
}

circle.active:hover {
  -webkit-animation: color_change 0.25s infinite alternate;
  animation: color_change 0.25s infinite alternate;
  stroke-width: 2px;
  stroke: #8cd;
  opacity: 0.5;
}

.error-node {
  stroke-width: 4px;
  stroke: #f44;
  fill: none;
}

.noerror-node {
  fill: none;
}

.selected-node {
  fill: #aef;
}

.unselected-node {
  fill: none;
}

svg.custom path.link {
  fill: none;
  stroke: #f5a623;
  stroke-width: 4px;
}

.node text {
  font-size: 16px;
  color: white;
}

div.custom-container {
  background-color: #242424;
}

svg.custom .node {
  cursor: pointer;
}

#root {
  padding: 0;
  margin: 0;
}

.custom {
  width: 100%;
  height: 100%;
}

#mindmap {
  height: 100%;
}

svg text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
svg text::-moz-selection {
  background: none;
}
svg text::selection {
  background: none;
}

.node-wrapper {
  margin-left: 200px;
  width: 100%;
  height: 100%;
}

/* SIDE PANEL */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
  display: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #fffdfd;
  /*padding: 2.5em 0.4em 0;*/
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #324157;
  padding: 0.5em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.modal-swagger-header {
  background-color: #242424 !important;
}

/* Swagger Editor fixes */
#editor-wrapper,
.editor-wrapper {
  height: 100%;
}

.swagger-preview {
  margin-top: 64px;
  overflow: auto;
}

.loading {
  text-align: center;
}

.loading-spinner-only {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: -10px;
}

.Pane {
  overflow-y: auto !important;
}

.title-menu:focus {
  outline: none;
}

.title-menu {
  font-size: 22px;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: -webkit-center;
}

.download-button {
  background-color: #d5d5d5 !important;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12) !important;
  padding: 6px 16px !important;
  font-size: 0.9rem !important;
  font-family: inherit !important;
  color: #324157 !important;
  min-width: 64px !important;
  box-sizing: border-box !important;
  position: fixed !important;
  right: 10px;
  text-decoration: none !important;
  margin: 0 !important;
  border-radius: 2px;
}

.dropdown {
  width: 100% !important;
}

.export-button {
  text-decoration: none !important;
  color: #28292b !important;
  text-align: -webkit-center;
  margin: 0px 0px 0px 0px !important;
}

.mindmaplist-container {
  border: 1px solid rgb(234, 238, 251);
  margin-top: 400px;
}

.mindmaplist-item {
  max-width: 100% !important;
}

.menu-swagger-list {
  z-index: 999;
}

.el-button {
  width: 100% !important;
  margin: 0 auto !important;
}

.form {
  margin: 10% auto;
  padding: 0 20%;
}

.custom-menu {
  display: none;
  z-index: 1000;
  position: fixed;
  border: 1px solid #ccc;
  background: #fff;
  color: #333;
}

.custom-ul {
  font-family: sans-serif;
  white-space: nowrap;
  overflow: hidden;
  list-style: none;
  padding: 0;
  margin: 0;
}

.custom-ul li {
  padding: 8px 12px;
  cursor: pointer;
}

.custom-ul li:hover {
  background-color: #def;
}

.small-tree {
  grid-column: 2;
}

.left-half-tree {
  grid-column: 1 / span 2;
}

.right-half-tree {
  grid-column: 2 / span 3;
}

.full-tree {
  grid-column: 1 / span 3;
}

.right-tree {
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  width: 100%;
  position: relative;
}

.relative-container {
  position: relative;
  width: 100%;
}

.buttonGroupRightOpen {
  position: absolute;
  right: 0;
  top: 0;
  grid-column: 2;
}

.buttonGroupRightClose {
  position: absolute;
  right: 0;
  top: 0;
  grid-column: 3;
}

.buttonGroupLeftOpen {
  position: absolute;
  left: 0;
  top: 0;
  grid-column: 2;
}

.buttonGroupLeftClose {
  position: absolute;
  left: 0;
  top: 0;
  grid-column: 1;
}

.swagger-ui {
  margin-top: 75px;
}

#live-preview .swagger-ui .wrapper {
  padding: 0 !important;
}

#live-preview .swagger-ui .scheme-container .schemes {
  padding: 0 20px !important;
}

.el-upload__tip {
  text-align: center !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

.array-prop-separator {
  margin: 10px -10px;
}

.array-prop-span {
  font-size: 0.8em;
  margin: 10px;
}

.el-upload-dragger {
  height: auto;
}

.el-upload-dragger .el-icon-upload {
  margin: 16px 0 16px;
}

.MindmapView-customResizeBorder-57:hover {
  background-color: #f1f0f0;
}

.ResizePanel-module_ResizeContentHorizontal__1gGbA {
  overflow: auto;
}

/* Prevent overflow issue on select input */
.MuiSelect-nativeInput {
  width: auto !important;
}

