@import url('https://fonts.googleapis.com/css?family=Montserrat');

.right {
  float: right !important;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

.el-menu-item {
  display: flex !important;
  padding: 0 !important;
  height: 6.4vh !important;
  font-size: calc(0.7em + 0.4vw) !important;
}

.el-menu-item > a, .el-menu-item > div {
  text-decoration: none;
  display: flex;
}

.link {
  align-self: center;
  font-style: 'normal';
}

.nav-links-spacing {
  padding: 0 1.2rem;
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-weight: lighter;
  text-align: center;
  color: #48576a;
}

h1 {
  margin: 0;
  height: 90px;
}

hr {
  border: 1px solid rgb(234, 238, 251);
  border-radius: 5px;
  margin: 30px 0;
  margin-bottom: 85px;
}

.full-width {
  width: 100% !important;
}

.shared-width {
  width: auto !important;
  min-width: fit-content;
  margin: 5px !important;
}

.double-ctn {
  width: 100%;
  display: inline-block;
  text-align: center;
}
